import { Component, Vue ,Watch} from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import { DeleteRequest, SearchRequest } from "@/api/shop/request";
import ShopSearch, { ListItem } from "@/store/shop/search";
import ShopGet from "@/store/shop/get";
import ShopModify from "@/store/shop/modify";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
import { ServiceProductManagementStorage } from "@/store/service-product-management-storage";
import Admin from "@/store/admin/admin";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDataTable, UIDialogDelete }
})
export default class Index extends Vue {
  // ------------
  // 固定値
  // ------------
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "店舗ID", value: "id" },
    { text: "店舗名", value: "name" },
    { text: "表示", value: "viewType" },
    { text: "", value: "space", sortable: false },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------

    // タイトル
    storageData = ServiceProductManagementStorage.getSessionStorage();
    headingMain = this.storageData.businessName + "管理";
    headingSub = this.storageData.businessName + " Management";
    breadCrumbs = [
      { text: "サービスプロダクト管理", disabled: true },
      { text: this.headingMain, disabled: true },
      { text: "店舗一覧", disabled: true }
      ];
  // 削除ダイアログの表示有無
  showDialog = false;

  // 削除される店舗名
  deletingShopName = "";

  // 削除される店舗ID
  deletingShopId = "";

  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    searchShopId: null as number | null,
    selectShopIds: null as number[] | null
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // ------------

  /**
   * 新規作成権限の有無
   */
  get writeFlg() {
    return ShopSearch.getWriteFlg;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return ShopSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return ShopSearch.getTotalCount;
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  
  setBusinessData(){
    //事業者名取得 
    const searchParams = new URLSearchParams(location.search);
    const businessId = Number(searchParams.get('businessId'));
    const businessName = String(searchParams.get("businessName"));
    let Request:{
      business_id : number,
      business_name : string
    } = {
      business_id : businessId ? businessId : 1,
      business_name : businessName ? businessName : "USEN"
    };
    ServiceProductManagementStorage.setSessionStorage(Request)
    this.storageData = ServiceProductManagementStorage.getSessionStorage();
    // タイトルを設定
    this.headingMain =   this.storageData.business_name + "管理";
    this.headingSub =  this.storageData.business_name + " Management";
    this.breadCrumbs = [
    { text: "サービスプロダクト管理", disabled: true },
    { text: this.headingMain, disabled: true },
    { text: "店舗一覧", disabled: true }
    ];
  }


  /**
   * 画面遷移した場合、画面をリロードする
   */
  @Watch("$route")
  watchRoute() {
    location.reload();
    Flash.clear();
  }
  
  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    
    //事業者情報取得
    this.setBusinessData();

    await ShopSearch.restore(this.inputOptions as SearchRequest);
    const request = ShopSearch.getSearchRequest;
    // 検索入力オプション
    request.searchShopId &&
      (this.inputOptions.searchShopId = request.searchShopId);
    request.selectShopIds &&
      (this.inputOptions.selectShopIds = request.selectShopIds);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopSearch.clearResponse();
    await ShopGet.clearResponse();
    await ShopModify.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async actionClickCallback(item: ListItem) {}

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async editClickCallback(item: ListItem) {
    await this.$router.push({
      name: "usen-manage-edit",
      params: { id: String(item.id) },
      query: { shopName: item.name }
    });
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  deleteClickCallback(item: ListItem) {
    this.showDialog = true;
    this.deletingShopId = item.id;
    this.deletingShopName = item.name;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDialog = false;
    if (!this.deletingShopId) {
      return;
    }

    this.isLoading = true;
    await ShopModify.deleteOne({
      id: this.deletingShopId
    } as DeleteRequest);
    if (ShopModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "店舗を削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: ShopModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    if (ShopGet.isSuccess) {
      return true;
    }
    const searchParams = new URLSearchParams(location.search);
    await ShopGet.get(Number(searchParams.get('businessId')));
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return ShopGet.isSuccess;
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    let isSuccess = await this.fetchShopList();
    if (!isSuccess) {
      this.isLoading = false;
      return;
    }

    let request = this.createRequest();
    await ShopSearch.search(request);
    if (!ShopSearch.isSuccess) {
      await Flash.setErrorNow({
        message: ShopSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  goToNew() {
    const name = this.storageData.business_name.toLowerCase()+ "-manage-new";
    this.$router.push({name : name}).then();
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    if (this.inputOptions.searchShopId) {
      request.searchShopId = this.inputOptions.searchShopId;
    } else {
      delete request.searchShopId;
    }

    if (this.inputOptions.selectShopIds) {
      request.selectShopIds = this.inputOptions.selectShopIds;
    } else {
      delete request.selectShopIds;
    }
    if(this.storageData.business_id){
      request.businessId = Number(this.storageData.business_id);
    }else{
      request.businessId = 1;
    }
    return request;
  }

  get isAdmin(){
    return Admin.isAdmin;
  }
}
