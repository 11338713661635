import { SessionStorage } from "@/store/session-storage";

const KEY_SERVICE_PRODUCT_MANAGEMENT = "key_service-product-management";

/**
 * ServiceProductManagementStorageを管理するクラス
 */
export class ServiceProductManagementStorage {

  static getSessionStorage() {
    return SessionStorage.getObject(KEY_SERVICE_PRODUCT_MANAGEMENT);
  }

  static setSessionStorage(val) {
    SessionStorage.setObject(KEY_SERVICE_PRODUCT_MANAGEMENT, val);
  }

  static removeSessionStorage() {
    SessionStorage.removeObject(KEY_SERVICE_PRODUCT_MANAGEMENT);
  }

}
